<template>
  <div class="text-center px-8 lg:px-20">
    <div class="mt-8 font-semibold text-base1 text-lg w-full">
      Meetric costs $5/month and we offer a 7-day free trial!
    </div>
    <div class="mt-8 font-semibold text-base1 text-lg w-full">
      Why? We're a small team of engineers with a mission to improve the way
      people meet and we need your support to improve the Meetric app.
    </div>
    <div class="flex mt-10">
      <m-button
        id="button_connected_next"
        @click="$emit('subscribe')"
        class="mx-auto px-6 py-5"
        segmentName="Onboarding-subscribe"
        icon="➡️"
        :pending="loadSubscribe"
        >Setup payment ($0 today)</m-button
      >
    </div>
    <div class="mt-10 font-small text-grey1 opacity-90">
      You will be redirected to Stripe, our payment partner (Meetric doesn't
      store your billing information). You will be debited in 7 days when the trial ends.
      Cancel anytime.
      <router-link
        class="underline cursor-pointer opacity-75 hover:opacity-100"
        :to="{ name: 'signout' }"
        >Sign out
      </router-link>
    </div>
  </div>
</template>
<script>
import MButton from '@/components/UI/MButton';

export default {
  name: 'Page1Connected',
  components: {
    MButton,
  },
  props: {
    loadSubscribe: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    userName() {
      if (this.$gAuth.basicProfile.getGivenName())
        return this.$gAuth.basicProfile.getGivenName();
      else if (this.$gAuth.basicProfile.getName())
        return this.$gAuth.basicProfile.getName();
      else return this.$gAuth.basicProfile.getEmail();
    },
    calendarName() {
      const calendars = this.$store.getters['selectedCalendars'].map((u) => {
        if (u == 'primary') {
          return this.$gAuth.basicProfile.getEmail();
        }
        return decodeURIComponent(u);
      });
      return calendars.join(', ');
    },
    connectionCssVars() {
      return {
        '--distance': this.mouseDistance,
        '--halo-opacity': this.haloOpacity,
      };
    },
  },
  data() {
    return {
      signingIn: false,
      nextButton: null,
      showConnections: false,
      mouseDistance: 1,
      haloOpacity: 0,
      colleaguesCount: 0,
    };
  },
  methods: {
    calculateDistance: function (event) {
      const mouseX = event.clientX;
      const mouseY = event.clientY;
      const rect = this.nextButton.getBoundingClientRect();
      const centreX = (rect.left + rect.right) / 2;
      const centreY = (rect.top + rect.bottom) / 2;
      const distance = Math.floor(
        Math.sqrt(Math.pow(mouseX - centreX, 2) + Math.pow(mouseY - centreY, 2))
      );
      this.mouseDistance = distance > 400 ? 1 : distance / 400;
      this.haloOpacity = distance > 100 ? 0 : 1 - distance / 100;
    },
    lightup: function () {
      this.showConnections = true;
    },
    lightsoff: function () {
      this.showConnections = false;
    },
    getColleaguesCount: function () {
      this.colleaguesCount = this.$store.getters['colleagues'].count;
    },
  },
  mounted() {
    this.nextButton = document.getElementById('button_connected_next');
    window.addEventListener('mousemove', this.calculateDistance);
    this.getColleaguesCount();
  },
  destroyed: function () {
    window.removeEventListener('mousemove', this.calculateDistance);
  },
};
</script>
<style scoped>
.halo {
  fill: #ffdf92;
  stroke: #ffdf92;
  fill-opacity: var(--halo-opacity);
}
.halospikes {
  animation: spikesconnected 0.2s ease none infinite alternate;
  fill: none;
  stroke: #ffdf92;
  stroke-width: 1.365;
  stroke-linecap: round;
  stroke-linejoin: miter;
  stroke-opacity: 1;
  font-variation-settings: normal;
  opacity: 1;
  vector-effect: none;
  fill-opacity: 1;
  stroke-miterlimit: 4;
  stroke-dasharray: none;
  stroke-dashoffset: 0;
  stop-color: #000000;
  stop-opacity: 1;
}
.connections path {
  animation: connected 1s ease none infinite alternate;
}

.creeping path {
  stroke-dashoffset: var(--distance);
  stroke-dasharray: 1;
}

@keyframes connected {
  from {
    stroke-dasharray: 0.05;
    stroke-dashoffset: 1;
  }
  to {
    stroke-dasharray: 0.05;
    stroke-dashoffset: 0;
  }
}
@keyframes spikesconnected {
  from {
    opacity: 0.3;
  }
  to {
    opacity: 0.5;
  }
}
</style>
