<template>
  <div
    class="
      lg:mx-auto
      bg-white
      dark:bg-base5
      p-4
      lg:p-8
      shadow-lg
      -mt-10
      lg:mt-10
      w-full
      lg:w-auto
    "
  >
    <div class="flex w-full px-8 lg:px-20">
      <div class="flex-none text-sm w-6">
        <div
          @click="$emit('page', { index: 2 })"
          class="
            cursor-pointer
            stroke-0
            text-grey3
            fill-current
            opacity-75
            hover:opacity-100
          "
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path
              d="M7.828 11H20v2H7.828l5.364 5.364-1.414 1.414L4 12l7.778-7.778 1.414 1.414z"
            />
          </svg>
        </div>
      </div>
      <div class="flex-grow text-base1 text-center text-lg font-semibold px-2">
        Select which calendars you would like to connect to Meetric
      </div>

      <div class="flex-none w-6"></div>
    </div>

    <!-- Calendars -->
    <user-calendars class="my-4" style="min-width: 13rem"></user-calendars>
    <div class="flex">
      <m-button @click="updateCalendars()" icon="➡️" class="mx-auto px-6 py-5"
        >Next</m-button
      >
    </div>
  </div>
</template>
<script>
import MButton from '@/components/UI/MButton';
import UserCalendars from '@/views/Profile/UserCalendars';
export default {
  name: 'Page4AddCalendar',
  components: {
    MButton,
    UserCalendars,
  },
  props: {},
  computed: {},
  data() {
    return {};
  },
  methods: {
    updateCalendars: function () {
      this.$emit('update');
      this.$emit('page', { index: 1 });
    },
  },
};
</script>
