<template>
  <div class="flex items-center justify-center w-full">
    <!-- Toggle Button -->
    <label class="flex items-center cursor-pointer">
      <!-- toggle -->
      <div class="relative">
        <!-- input -->
        <input
          type="checkbox"
          class="hidden"
          v-bind:checked="value"
          v-on:input="onInput($event.target.checked)"
        />
        <!-- line -->
        <div
          class="toggle__line h-5 rounded-full bg-grey4"
          style="width: 2.4rem"
        ></div>
        <!-- dot -->
        <div
          class="toggle__dot absolute bg-white dark:bg-base5 rounded-full mt-1"
          style="width: 1rem; height: 1rem"
        >
          <div v-if="pending">
            <m-spinner class="text-grey4 p-1" />
          </div>
        </div>
      </div>
      <!-- label -->
      <div class="text-grey4 font-medium">
        <slot></slot>
      </div>
    </label>
  </div>
</template>

<script>
import MSpinner from './MSpinner.vue';
export default {
  components: { MSpinner },
  name: 'MToggle',
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    pending: {
      type: Boolean,
      required: false,
      default: false,
    },
    segmentName: {
      type: String,
      required: false,
    },
  },
  data() {
    return {};
  },
  created() {},
  methods: {
    onInput(checked) {
      this.$emit('input', checked);

      if (this.segmentName) {
        const props = {
          source: this.$parent?.$parent?.$vnode?.tag || '',
          value: checked,
        };
        window.meetric.track(this.segmentName, props);
      }
    },
  },
};
</script>

<style>
.toggle__dot {
  top: -0.125rem;
  left: 0.15rem;
  transition: transform 0.3s ease-in-out;
}

input:checked ~ .toggle__line {
  @apply bg-base2;
}
input:checked ~ .toggle__dot {
  transform: translateX(110%);
}
</style>
