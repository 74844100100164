<template>
  <div class="text-center px-8 lg:px-20">
    <div class="text-base1 font-semibold text-3xl">
      👋 Welcome {{ userName }}
    </div>
    <div class="mt-8 mb-5 font-semibold text-base1 text-lg w-full">
      Once you've finished the onboarding on https://my.meetric.app, refresh
      this page to use the Meetric extension.
    </div>

    <div class="flex">
      <m-button
        id="button_refresh"
        @click="$emit('home')"
        class="mx-auto px-6 py-5"
        segmentName="extension-refresh"
        icon="🔁"
        >Refresh</m-button
      >
    </div>
  </div>
</template>
<script>
import MButton from '@/components/UI/MButton';

export default {
  name: 'Extension',
  components: {
    MButton,
  },
  computed: {
    userName() {
      if (this.$gAuth.basicProfile.getGivenName())
        return this.$gAuth.basicProfile.getGivenName();
      else if (this.$gAuth.basicProfile.getName())
        return this.$gAuth.basicProfile.getName();
      else return this.$gAuth.basicProfile.getEmail();
    },
    calendarName() {
      const calendars = this.$store.getters['selectedCalendars'].map((u) => {
        if (u == 'primary') {
          return this.$gAuth.basicProfile.getEmail();
        }
        return decodeURIComponent(u);
      });
      return calendars.join(', ');
    },
    connectionCssVars() {
      return {
        '--distance': this.mouseDistance,
        '--halo-opacity': this.haloOpacity,
      };
    },
  },
  data() {
    return {
      signingIn: false,
      nextButton: null,
      showConnections: false,
      mouseDistance: 1,
      haloOpacity: 0,
      colleaguesCount: 0,
    };
  },
  methods: {},
};
</script>

