<template>
  <div
    class="flex justify-between items-start py-2 lg:p-3 rounded-lg hover:bg-grey6 dark:hover:bg-base5"
  >
    <div class="flex items-start flex-grow my-auto">
      <div
        class="text-grey3 w-6 h-6 my-auto fill-current stroke-current hidden lg:flex lg:flex-col lg:justify-center lg:items-center"
      >
        <!-- @slot Icon shown alongside preference in desktop mode -->
        <slot name="icon"></slot>
      </div>
      <div class="flex my-auto flex-grow">
        <div class="flex flex-wrap items-center flex-grow">
          <!-- @slot Title label -->
          <div
            class="flex text-grey1 font-medium text-base lg:ml-4 w-full lg:w-auto"
          >
            <slot name="title"></slot>
          </div>

          <div
            class="text-grey3 font-normal text-base lg:ml-4 w-full lg:w-auto"
          >
            <!-- @slot Subtitle label -->
            <slot name="subtitle"></slot>
          </div>
        </div>
      </div>
    </div>

    <div class="flex items-start ml-2 lg:ml-4 mt-0.5">
      <!-- @slot Control element (e.g. toggle, select box) -->
      <slot name="control"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PreferenceItem',
  components: {},
  methods: {},
};
</script>

<style scoped></style>
