<template>
  <div>
    <!-- Calendar placeholder -->
    <div v-if="calendars.length == 0">
      <m-placeholder class="h-6 rounded-lg my-3 w-full"></m-placeholder>
      <m-placeholder class="h-6 rounded-lg my-3 w-full"></m-placeholder>
    </div>
    <div v-for="c in calendars" :key="c.id">
      <preference-item>
        <template v-slot:icon>
          <div
            :style="'background-color:' + c.backgroundColor"
            class="h-4 w-4 rounded"
          ></div>
        </template>

        <template v-slot:title>
          <span data-recording-sensitive>{{
            c.summaryOverride || c.summary
          }}</span>
        </template>

        <template v-slot:control
          ><m-toggle
            @input="setCalendar(c.id, c.showInMeetric)"
            v-model="c.showInMeetric"
            segmentName="Toggle calendar"
          ></m-toggle
        ></template>
      </preference-item>
    </div>
  </div>
</template>

<script>
import MToggle from '@/components/UI/MToggle';
import MPlaceholder from '@/components/UI/MPlaceholder';
import PreferenceItem from '@/components/UI/PreferenceItem';
import { getCalendars } from '@/api/Google';
import { addCalendar, removeCalendar } from '@/api/Cherry';

export default {
  name: 'UserCalendars',
  components: {
    MToggle,
    MPlaceholder,
    PreferenceItem,
  },
  data() {
    return {
      calendars: [],
    };
  },
  computed: {
    localCalendars() {
      return this.$store.getters['selectedCalendars'];
    },
  },
  mounted() {
    this.fetchCalendars();
  },
  methods: {
    updateCalendarValues(calendars) {
      this.calendars = calendars.map((o) => {
        o.showInMeetric = this.localCalendars.some(
          (l) => l == encodeURIComponent(o.id)
        );
        return o;
      });
    },
    fetchCalendars() {
      getCalendars().then((response) => {
        //response.items.map((o) => (o.showInMeetric = o.primary == true));
        response.items.find((o) => o.primary == true).id = 'primary';
        let primary = null;
        const items = response.items.filter((i) => {
          if (i.primary == true) {
            primary = i;
            return false;
          }
          return true;
        });
        // reorder so primary is first
        if (primary) response.items = [primary, ...items];

        if (this.localCalendars) {
          this.updateCalendarValues(response.items);
        } else {
          this.calendars = response.items.map((o) => {
            o.showInMeetric = false;
            return o;
          });
        }
      });
    },
    setCalendar(id, value) {
      if (value) {
        addCalendar([id]).then((result) => {
          if (result) {
            this.$store.commit('saveCalendars', result);
            // empty saved meeting list so all events are fetched again
            this.$store.dispatch('clearMeetingList');
            this.updateCalendarValues(this.calendars);
          } else {
            this.$snack("Oops - we couldn't enable calendar");
          }
        });
      } else {
        removeCalendar(id).then((result) => {
          if (result) {
            this.$store.commit('saveCalendars', result);
            this.updateCalendarValues(this.calendars);
          } else {
            this.$snack("Oops - we couldn't disable calendar");
          }
        });
      }
    },
  },
};
</script>
