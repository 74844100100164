<template>
  <div
    class="flex items-center justify-center w-full"
    :class="{ 'action-colour': !filtercolour }"
  >
    <label class="flex items-center group cursor-pointer">
      <div class="relative">
        <input
          type="checkbox"
          class="hidden"
          v-bind:checked="value"
          :disabled="disabled || pending"
          v-on:input="onInput($event.target.checked)"
        />
        <div
          class="flex checkbox-line rounded border-2 border-solid border-grey5 group-hover:border-grey4"
          style="width: 1.15rem; height: 1.15rem"
        >
          <div class="checkbox-container">
            <div class="checkbox-line-one opacity-0"></div>
            <div class="checkbox-line-two opacity-0"></div>
          </div>
        </div>
      </div>
      <div class="text-grey3 font-medium dark:hover:text-white">
        <span>
          <slot></slot>
        </span>
      </div>
    </label>
  </div>
</template>

<script>
export default {
  name: 'MCheckbox',
  components: {},
  props: {
    value: {
      type: Boolean,
      required: true,
      default: true,
    },
    filtercolour: {
      type: Boolean,
      required: false,
      default: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    pending: {
      type: Boolean,
      required: false,
      default: false,
    },
    segmentName: {
      type: String,
      required: false,
    },
    segmentLabel: {
      type: String,
      required: false,
    },
  },
  data() {
    return {};
  },
  created() {},
  methods: {
    onInput(checked) {
      this.$emit('input', checked);

      if (this.segmentName) {
        const props = {
          source: this.$parent.$parent.$vnode.tag,
          value: checked,
          label: this.segmentLabel || '',
        };
        window.meetric.track(this.segmentName, props);
      }
    },
  },
};
</script>

<style scoped>
input:checked ~ .checkbox-line {
  @apply border-base2;
}

input:checked ~ .checkbox-line > .checkbox-container > .checkbox-line-one,
input:checked ~ .checkbox-line > .checkbox-container > .checkbox-line-two {
  @apply rounded bg-base2 absolute opacity-100 transition-opacity duration-300 ease-linear;
}

input:checked ~ .checkbox-line > .checkbox-container > .checkbox-line-one {
  width: 0.14rem;
  height: 0.6rem;
  left: 0.35rem;
  top: -0.13rem;
}

input:checked ~ .checkbox-line > .checkbox-container > .checkbox-line-two {
  width: 0.35rem;
  height: 0.13rem;
  left: 0.13rem;
  top: 0.34rem;
  -ms-transform: rotate(15deg); /* IE 9 */
  -webkit-transform: rotate(15deg); /* Chrome, Safari, Opera */
  transform: rotate(15deg);
}

.checkbox-container {
  margin-left: 0.02rem;
  margin-top: -0.04rem;
  -ms-transform: rotate(37deg); /* IE 9 */
  -webkit-transform: rotate(37deg); /* Chrome, Safari, Opera */
  transform: rotate(37deg);
}

.action-colour .checkbox-line {
  @apply border-grey4;
}

.action-colour .checkbox-line:hover {
  @apply border-grey3 border-opacity-75;
}

.action-colour input:checked ~ .checkbox-line {
  @apply border-alertvalidation;
}
.action-colour input:checked ~ .checkbox-line:hover {
  @apply border-alertvalidationlight;
}
.action-colour
  input:checked
  ~ .checkbox-line
  > .checkbox-container
  > .checkbox-line-one,
.action-colour
  input:checked
  ~ .checkbox-line
  > .checkbox-container
  > .checkbox-line-two {
  @apply bg-alertvalidation;
}
</style>
